
















































































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";

export default defineComponent({
  components: {
    MPartnerItem: () => import("@/components/molecules/m-partner-item.vue"),
  },

  props: {
    paymentType: {
      type: String,
      required: false,
      default: "",
    },
  },

  setup(props, { root }) {
    const state = reactive({
      headers: [
        { value: "name", text: root.$tc("layout.misc.name") },
        { value: "type", text: root.$tc("layout.misc.type") },
        {
          value: "currency",
          text: root.$tc("event.panel.info.currency.title"),
        },
        {
          value: "isActive",
          text: root.$tc("event.panel.abstract.config.settings.status.active"),
        },
        { text: "", value: "data-table-expand" },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get("payment-credentials", {
          params: {
            sortBy,
            sortDesc,
            page,
            itemsPerPage,
            type: props.paymentType || undefined,
          },
        })
        .then(({ data: { paymentCredentials, total } }) => {
          state.empty = false;
          state.items = paymentCredentials.map(
            (paymentCredential: any, index: number) => ({
              id: index,
              ...paymentCredential,
            })
          );
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);
    watch(() => props.paymentType, fetchData, { deep: true });
    watch(() => state.options, fetchData, { deep: true });

    return { state, fetchData };
  },
});
